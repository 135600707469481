import React, {useState, useEffect} from 'react'
import {graphql, Link} from 'gatsby'
import PubSub from 'pubsub-js'
import Img from 'gatsby-image'
import SEO from '../components/seo/SEO'
import Cta from '../components/Cta'
import Texte from '../components/Texte'
// import Form from '../components/Form'
import ImageZoomable from '../components/ui/ImageZoomable'
import Tabs from '../components/ui/Tabs'
import ZoneCard from '../components/ZoneCard'
import {_getLocalizedText, _getZonesPath} from '../lib/helpers'

export const pageQuery = graphql`
  query ZoneQuery($_id: String!, $_idFR: String!, $locale: String!) {
    zoneFR:sanityZone(_id: {eq: $_idFR}) {
      ...zone
    }
    zone: sanityZone(_id: {eq: $_id}, locale: {eq: $locale}) {
      ...zone
    }
  }
`

const Zones = ({pageContext, data}) => {
  // console.log(pageContext)
  /**
   * initial
   * form
   * success
   */
  const [status, setStatus] = useState('initial')
  // const [backUrl, setBackUrl] = useState("")

  useEffect(() => {
    if (imageCoupe) {
      PubSub.publish('COUPE', imageCoupe.asset.url)
    }
    // if(typeof history !== 'undefined' && document.referrer){
    //   setBackUrl(document.referrer)
    // }
  }, [])

  useEffect(() => {
    PubSub.publish('ZONE_STATUS', status)
    window.scroll(0, 0)
  }, [status])

  const {
    _id,
    prix_total,
    contributions
  } = data.zoneFR

  const {
    // _id,
    seo,
    title,
    texte,
    tabs,
    imageFeatured,
    imageCoupe
  } = data.zone
  // console.log(data.zoneFR)
  const contributionMax = prix_total - contributions
  // on update que la version fr
  // const zoneIdFR = _id.indexOf('__i18n_') > -1
  // ? _id.split('__i18n_')[0]
  // : _id
  const zoneIdFR = _id
  // console.log(zoneIdFR)
  // return null
  return (
    <div className={`zone h100 is-${status} ${status === 'form' ? 'is-form' : ''}`}>
      <SEO
        pageTitle={title}
        pageDescription={seo ? seo.description : ''}
        template='template-zone'
        // pageBanner={seo ? seo.image.asset.url : 'https://cdn.sanity.io/images/dyrwy3jp/production/61908d352b0422cb8a1923ed2ff3bd0c2a6a9976-1440x900.jpg'}
        pageBanner={'https://cdn.sanity.io/images/dyrwy3jp/production/61908d352b0422cb8a1923ed2ff3bd0c2a6a9976-1440x900.jpg'}
        page
      />

      <div className='container h100'>
        {status === 'initial' &&
        <>
          <div className='row between-xs hidden-xs'>
            <div className='col-sm-4 '>
              <aside className='aside '>
                <div className='top'>
                  <Cta label={_getLocalizedText('vueEnsemble')} url={_getZonesPath()} icon='arrow-w' />
                  <h1 className='ttu'>{title}</h1>
                  <Texte texte={texte} />
                  {tabs && <Tabs input={tabs} />}
                </div>
                <div className='bottom'>
                  {/* <button onClick={() => setStatus('form')} className='btn btn-secondary btn-lg'>
                    <span>{_getLocalizedText('financer')}</span>
                    <span className='icon icon-arrow-e' />
                  </button> */}
                </div>
              </aside>
            </div>
            <div className='col-sm-3 '>
              {imageFeatured && <ImageZoomable image={imageFeatured} />}
            </div>
          </div>

          <div className='xs-only'>
            <div className='zone-header'>
              <Cta label={_getLocalizedText('vueEnsemble')} url={_getZonesPath()} icon='arrow-w' />
              <h1 className='ttu'>{title}</h1>
            </div>
            <div className='zone-content'>
              <Texte texte={texte} />
              {imageFeatured && <Img {...imageFeatured.asset} Tag='figure' />}

              {tabs && <Tabs input={tabs} />}

              {/* <button onClick={() => setStatus('form')} className='btn btn-secondary btn-lg has-shadow'>
                <span>{_getLocalizedText('financer')}</span>
                <span className='icon icon-arrow-e' />
              </button> */}
            </div>
          </div>
        </>
        }

        

        
      </div>
    </div>
  )
}

export default Zones
